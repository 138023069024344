<template>
  <div class="w-full h-screen flex items-center justify-center">
    <n-card style="width: 450px" class="-mt-40">
      <n-tabs default-value="signin" size="large" justify-content="space-evenly">
        <n-tab-pane name="signin" tab="登录">
          <n-button type="primary" block @click="handleLogin">登录</n-button>
        </n-tab-pane>
        <n-tab-pane name="signup" tab="注册">
          <n-form label-placement="left" label-width="70px" label-align="left">
            <n-form-item label="用户名">
              <n-input />
            </n-form-item>
            <n-form-item label="密码">
              <n-input />
            </n-form-item>
            <n-form-item label="重复密码">
              <n-input />
            </n-form-item>
          </n-form>
          <n-button type="primary" block>注册</n-button>
        </n-tab-pane>
      </n-tabs>
    </n-card>
  </div>
</template>

<script setup>
import { setToken } from '@/utils/auth';
function handleLogin() {
  setToken(
    'bearer eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiI1MzczNzQiLCJyb2xlX25hbWUiOiJwb2xpY3lfc3R1ZGVudCIsInVzZXJfaWQiOiIxNDIxMTUwMzIyMjg5NjU5OTA2Iiwicm9sZV9pZCI6IjEzNjUyNDgyOTMzMjc2MDU3NjIiLCJ1c2VyX25hbWUiOiJ6aGVuamllIiwib2F1dGhfaWQiOiIiLCJ0b2tlbl90eXBlIjoiYWNjZXNzX3Rva2VuIiwiYWNjb3VudCI6InpoZW5qaWUiLCJjbGllbnRfaWQiOiJzYWJlciIsImV4cCI6MTYzNDM2NzI3NywibmJmIjoxNjMzMDcxMjc3fQ.c96z8GL0t5RH7lDRfNvFivvdT10FJgkVkJzoSSBjJ_g',
  );
}
</script>
